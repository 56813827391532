<template>
  <div>
    <ValidationObserver ref="observer">
      <b-field :label="labels.urlVideo">
        <span class="is-flex">
          <b-input
            :placeholder="labels.urlVideo"
            type="text"
            v-model="form.applicationLinkVideo"
            class="is-flex-grow-1"
          />
          <b-tooltip
            animated
            class="is-flex-grow-0 is-flex-shrink-1 is-flex is-flex-direction-column is-justify-content-center ml-2"
            label="Abrir enlace"
            position="is-top"
            type="is-success"
          >
            <a
              :href="form.applicationLinkVideo"
              target="_blank"
              disabled
            >
              <f-icon
                class="mx-4"
                icon="fa-regular fa-up-right-from-square"
              />
            </a>
          </b-tooltip>
        </span>
      </b-field>

      <div class="mb-2 mt-2 columns is-multiline">
        <div class="column is-12 mt-2 pt-0 pb-0 label">
          {{ labels.uploadFile }} formato PDF con peso máximo 5MB
        </div>
        <ValidationProvider
          rules="fileSize:5"
          name="applicationFile"
          v-slot="{ errors, valid }"
        >
          <b-field
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
            class="ml-3 mb-1"
          >
            <b-upload
              v-model="form.applicationFile"
              accept=".pdf"
            >
              <a class="button is-primary is-fullwidth">
                <b-icon icon="upload" />
                <span>Subir {{ labels.uploadFile }}</span>
              </a>
            </b-upload>
          </b-field>
        </ValidationProvider>

        <b-tooltip
          label="Abrir archivo"
          position="is-top"
          type="is-success"
          animated
        >
          <a
            v-if="form.applicationFileMetadata"
            class="file-name"
            :href="form.applicationFileMetadata.url"
            target="_blank"
          >
            {{ form.applicationFileMetadata.name }}
          </a>
        </b-tooltip>
        <b-button
          v-if="form.applicationFileMetadata"
          class="ml-10"
          type="is-danger"
          icon-left="trash"
          @click="deleteApplicationFile"
        />
        <div
          class="mt-0 mb-0 pt-0 pb-0 column is-12"
          v-if="form.applicationFile && form.applicationFile.size"
        >
          Peso del archivo: {{ Number(form.applicationFile.size / (1024 * 1024)).toFixed(2) }} MB
        </div>
      </div>
      
      <b-field :label="labels.aditionalComments">
        <textarea
          :placeholder="labels.aditionalComments"
          v-model="form.aditionalComments"
          rows="3"
          class="is-flex-grow-1 textarea"
        />
      </b-field>
    </ValidationObserver>
  </div>
</template>

<script>
import { customEvaluationForm } from '@/mixins/customEvaluationForm';
export default {
  props: {
    customEvaluationForm: {
      default: () => null,
      required: false
    }
  },
  mixins: [customEvaluationForm], 
  data() {
    return {
      
    };
  },

  mounted() {
    this.setcustomEvaluationForm()
  },
  computed: {
    labels() {
      return this.$t("screens.candidate.detail");
    },
    id() {
      return this.$route.params.id;
    }
  },
  watch: {
    "form.applicationFile": function () {
        this.uploadApplicationFile();
      }
  },
  methods: {
    setcustomEvaluationForm(){
      if(this.customEvaluationForm){
        this.form = this.customEvaluationForm
      }
    },
    async validateS3FileSize(file) {
        /* Cheking */
        if (file?.size && file.size / (1024 * 1024) > 5) {
          this.$buefy.dialog.alert({
            title: "¡Oooops!",
            message: "Lo siento, pero el archivo de aplicación debe pesar menos de 5MB",
            type: "is-danger",
            hasIcon: true,
            confirmText: "Ok, lo entiendo",
          });
          this.form.applicationFile = null;
          if (this.form.applicationFileMetadata) await this.deleteApplicationFile();
          return false;
        }
        return true;
      },
      async uploadApplicationFile() {
        const file = this.form.applicationFile;
        if (!file) return;

        // Validating file size
        const isAdmittedSize = await this.validateS3FileSize(file);
        if (!isAdmittedSize) return;

        const payload = new FormData();
        payload.append("file", file);

        try {
          this.isLoading = true;
          const { data } = await this.$api.post(`application/${this.id}/application-file`, payload);
          this.form.applicationFileMetadata = data;
        } catch (error) {
          const message = error.response.data?.message || error;
          this.$buefy.toast.open({
            message: message,
            type: "is-danger",
          });
          this.form.applicationFile = null;
        }
        this.isLoading = false;
      },
      async deleteApplicationFile() {
        const fileMetadata = this.form.applicationFileMetadata;
        if (!fileMetadata) return;

        const payload = {
          key: fileMetadata.key,
        };

        try {
          await this.$api.post(`application/${this.id}/application-file/delete`, payload);
          this.form.applicationFile = null;
          this.form.applicationFileMetadata = null;
        } catch (error) {
          const message = error.response.data?.message || error;
          this.$buefy.toast.open({
            message: message,
            type: "is-danger",
          });
        }
      },
  },
};
</script>

<style lang="scss" scoped>

</style>